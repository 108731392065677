import React, { useEffect, useState } from 'react'
import { TldrawApp, TDExportType } from '@tldraw/tldraw'
import { SaveIcon } from 'components/icons/SaveIcon'
import { WebTryoutIcon } from 'components/icons/WebTryoutIcon'
import Image from 'next/image'
import SideBar, { PANELS } from 'components/SideBar'
import { SettingPanel } from './UI/SettingPanel'
import { styled } from 'styles'
import TopPanel from './TopPanel'

const AppBar: React.FC<React.PropsWithChildren<{ app: TldrawApp | undefined }>> = ({
  app,
  children,
}) => {
  const [saving, setSaving] = useState(false)
  const [activeLeftPanel, setActiveLeftPanel] = useState<PANELS>(PANELS.CANVAS)

  const handleSave = async () => {
    setSaving(true)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await app?.exportImage(TDExportType.PNG, {
      scale: 2,
      quality: 1,
      transparentBackground: true,
      wholePage: true,
    })
    setSaving(false)
  }

  useEffect(() => {
    if (app) {
      const handleCameraZoom = () => {
        const pid = app?.appState.currentPageId
        const pageSize = app?.document.pages[pid]?.size
        const ratio = app?.document.pageStates[pid].camera.zoom
        const _w = pageSize[0]
        const _h = pageSize[1]
        const root = document.documentElement
        root.style.setProperty('--canvas-width', `${_w * ratio}px`)
        root.style.setProperty('--canvas-height', `${_h * ratio}px`)
      }
      const handleCanvasPan = (e) => {
        const _w = document.getElementById('_canvas')
        _w.scrollLeft += e?.detail?.[0]
        _w.scrollTop += e?.detail?.[1]
      }
      document.addEventListener('setCameraZoom', handleCameraZoom)
      document.addEventListener('setCanvasPan', handleCanvasPan)
      return () => {
        document.addEventListener('setCameraZoom', handleCameraZoom)
        document.addEventListener('setCanvasPan', handleCanvasPan)
      }
    }
  }, [app])

  return (
    <>
      <Navbar>
        <LogoWrapper>
          {/* <Image src="/images/pictext_logo.png" alt="PicText" width="170" height="75" /> */}
          <span>PicGrid</span>
          {/* <WebTryoutIcon /> */}
        </LogoWrapper>
        <ExportButton onClick={handleSave}>
          <SaveIcon />
          {saving ? 'Saving...' : 'Done'}
        </ExportButton>
      </Navbar>
      <Container>
        <SideBar app={app} active={activeLeftPanel} setActive={setActiveLeftPanel} />
        <ColumnWrapper>
          <Section
            css={{
              height: 1,
              overflow: 'visible',
              background: 'var(--topbar-bg)',
              borderBottom: '1px solid #f3f3f3',
            }}
          >
            <TopPanel app={app} />
          </Section>
          <Section
            css={{ flex: 1, maxWidth: 'calc(100vw - 80px - var(--panel-width))' }}
            id="_canvas"
          >
            <CanvasWrapper>
              {/* Base background to handle opacity */}
              {/* <Background css={{ background: 'white' }} /> */}
              {/* Real background */}
              {/* <Background css={{ background, backgroundSize }} /> */}
              {children}
            </CanvasWrapper>
          </Section>
        </ColumnWrapper>
        <SettingPanel app={app} leftPanel={activeLeftPanel} />
      </Container>
    </>
  )
}
export default AppBar

const Navbar = styled('div', {
  height: 80,
  padding: '16px 80px',
  // background: '#FFFFFF',
  background: '#F9F6ED',
  // background: 'var(--header-bg)',
  // borderBottom: '1px solid #ddd',
  color: '#4C4C4C',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const LogoWrapper = styled('div', {
  // fontFamily: '"Averta PE", -apple-system, BlinkMacSystemFont',
  fontSize: 40,
  fontWeight: 700,
  lineHeight: '49px',
  letterSpacing: '0px',
  display: 'flex',
  alignItems: 'center',
  '& span': {
    // background: 'linear-gradient(90deg, #7058B6 0%, #A15299 35.42%, #DF3C70 68.26%, #EA446A 100%)',
    // WebkitBackgroundClip: 'text',
    // WebkitTextFillColor: 'transparent',
    // backgroundClip: 'text',
    // textFillColor: 'transparent',
    color: '#3B3E30',
  },
})

const ExportButton = styled('button', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 6,
  padding: '0px 16px',
  minWidth: 105,
  height: 44,
  backgroundColor: '#4FC3C4',
  border: 'none',
  borderRadius: 66,
  color: 'white',
  fontFamily: '"Montserrat", -apple-system, BlinkMacSystemFont',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 16,
  lineHeight: '20px',
  cursor: 'pointer',
  userSelect: 'none',
  pointerEvents: 'all',
  '&:disabled': {
    cursor: 'not-allowed',
    backgroundColor: '#3F9C9C',
  },
  [`&:hover:not(:disabled)`]: {
    backgroundColor: '#47AFB0',
  },
  [`&:focus:not(:disabled)`]: {
    backgroundColor: '#47AFB0',
  },
})

const Container = styled('div', {
  width: '100%',
  height: 'calc(100vh - 80px)',
  display: 'flex',
})

const Wrapper = styled('div', {
  flexGrow: 1,
  overflow: 'scroll',
  position: 'relative',
})

const ColumnWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
})

const Section = styled('div', {
  width: '100%',
  position: 'relative',
  overflow: 'scroll',
})
const CanvasWrapper = styled('div', {
  padding: '64px',
  background: '#f3f3f3',
  position: 'relative',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'fit-content',
  minWidth: '100%',
  height: 'fit-content',
  minHeight: '100%',
})

const Background = styled('div', {
  width: 'var(--canvas-width)',
  height: 'var(--canvas-height)',
  position: 'absolute',
  top: '50%',
  left: '50%',
  boxShadow: 'none',
  transform: 'translate(-50%, -50%)',
})
