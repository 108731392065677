export function LayoutIcon(): JSX.Element {
  return (
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.88679 6.75H33.1132C33.4649 6.75 33.75 7.0351 33.75 7.38679V33.6132C33.75 33.9649 33.4649 34.25 33.1132 34.25H6.88679C6.5351 34.25 6.25 33.9649 6.25 33.6132V7.38679C6.25 7.0351 6.5351 6.75 6.88679 6.75Z"
        stroke="currentColor"
        strokeWidth="2.5"
      />
      <path
        d="M20 7.5V33.5"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 20.5H33"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
