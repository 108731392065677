import { Utils } from '@tldraw/core'
import { TDShapeType, TextPreset, TldrawApp } from '@tldraw/tldraw'
import { BackgroundIcon } from 'components/icons/BackgroundIcon'
import { LayoutIcon } from 'components/icons/LayoutIcon'
import { TextIcon } from 'components/icons/TextIcon'
import { StickersIcon } from 'components/icons/StickersIcon'
import { CanvasIcon } from 'components/icons/CanvasIcon'
import { ImageIcon } from 'components/icons/ImageIcon'
import { GiphyIcon } from 'components/icons/GiphyIcon'
import Image from 'next/image'
import { FC, useEffect } from 'react'
import { styled } from 'styles'
import grids from 'utils/grids.json'
import canvasSizes from 'utils/canvasSize.json'
import textPresets from 'utils/textPresets.json'
import { BackgroundPanel } from './UI/BackgroundPanel'
import { StickerPanel } from './UI/StickerPanel'
import { GiphyPanel } from './UI/GiphyPanel'
import { ImagePanel } from './UI/ImagePanel'

export enum PANELS {
  NONE,
  LAYOUT,
  BACKGROUND,
  TEXT,
  IMAGE,
  STICKERS,
  CANVAS,
  GIPHY,
}

const gridsMap = Object.fromEntries(grids.map((g) => [g.name, g]))
const sortedGridNames = grids
  .map((g) => g.name)
  .sort((a, b) => {
    const [slotA, orderA] = a.split('-').map(Number)
    const [slotB, orderB] = b.split('-').map(Number)
    if (slotA < slotB) return -1
    if (slotA > slotB) return 1
    if (orderA < orderB) return -1
    if (orderA > orderB) return 1
    return 0
  })
const gridOptions = sortedGridNames.map((n) => gridsMap[n])
// const gridOptions = grids.sort(
//   (a, b) => Number(a.name.split('-')[0]) - Number(b.name.split('-')[0])
// )

const MARGIN = 0.0125
const GRID_SIZE = 1
const INNER_GRID_SIZE = GRID_SIZE - MARGIN * 2
const GridElement: FC<{ name: string; slots: { rect: number[][] }[] }> = ({
  name,
  slots,
  ...rest
}) => {
  const rects = slots.map(({ rect }) => ({
    // ...s,
    x: rect[0][0] * INNER_GRID_SIZE + MARGIN,
    y: rect[0][1] * INNER_GRID_SIZE + MARGIN,
    width: Math.abs(rect[1][0] * INNER_GRID_SIZE),
    height: Math.abs(rect[1][1] * INNER_GRID_SIZE),
  }))

  return (
    <svg viewBox="0 0 1 1" xmlns="http://www.w3.org/2000/svg" id={name} {...rest}>
      {rects.map((r, i) => (
        <rect
          key={name + r.x + r.y + 'rect' + i}
          {...r}
          fill="#D8D8D8"
          stroke="white"
          strokeWidth={MARGIN * 2}
        />
      ))}
    </svg>
  )
}

const Panel: FC<{
  active: PANELS
  onClick: (...a: unknown[]) => void
  onAddImage: (...a: unknown[]) => void
  app: TldrawApp
}> = ({ active, onClick, onAddImage, app }) => {
  const onClickGrid = (grid: typeof gridOptions[0]) => () => onClick(grid)
  const onClickCanvasOption = (value) => () => app.setCanvasSize(value)

  switch (active) {
    case PANELS.CANVAS:
      return (
        <LayoutPanel>
          <GridListWrapper>
            {canvasSizes.map((c) => (
              <GridWrapper key={c.name} onClick={onClickCanvasOption(c.value)}>
                <CustomCanvasSizeElement
                  css={{
                    width: (c.value[0] / 640) * 100,
                    height: (c.value[1] / 640) * 100,
                  }}
                >
                  {c.name}
                </CustomCanvasSizeElement>
              </GridWrapper>
            ))}
          </GridListWrapper>
        </LayoutPanel>
      )
    case PANELS.LAYOUT:
      return (
        <LayoutPanel>
          <GridListWrapper>
            {gridOptions.map((g) => (
              <GridWrapper key={g.name} onClick={onClickGrid(g)}>
                <CustomGridElement name={g.name} slots={g.slots} />
              </GridWrapper>
            ))}
          </GridListWrapper>
        </LayoutPanel>
      )
    case PANELS.BACKGROUND:
      return <BackgroundPanel onChooseBg={onClick} app={app} />
    case PANELS.TEXT:
      return (
        <TextPanel>
          <TextButton onClick={() => onClick()}>Add text</TextButton>
          <TextListWrapper>
            {textPresets.map((t) => (
              <TextWrapper key={t.name} onClick={() => onClick(t)}>
                <Image
                  src={`/images/${t.name}_preview.png`}
                  alt={t.name}
                  width="128"
                  height="128"
                />
              </TextWrapper>
            ))}
          </TextListWrapper>
        </TextPanel>
      )
    case PANELS.IMAGE:
      return <ImagePanel onClickUpload={onClick} onAddImage={onAddImage} />
    case PANELS.STICKERS:
      return <StickerPanel onChooseSticker={onClick} />
    case PANELS.GIPHY:
      return <GiphyPanel onClick={onClick} />
    case PANELS.NONE:
    default:
      return null
  }
}

const SideBar: FC<{
  app: TldrawApp
  active: PANELS
  setActive: React.Dispatch<React.SetStateAction<PANELS>>
}> = ({ app, active, setActive }) => {
  const onClickPanel = (panel: PANELS) => () =>
    setActive((current) => {
      window.dispatchEvent(new Event('toggleSideBar'))
      return current === panel ? PANELS.NONE : panel
    })

  const onClickCanvasOption = (value) => app.setCanvasSize(value)

  const onClickGrid = (grid: typeof gridOptions[0]) => app.addSlots(grid)

  const onClickBackground = (bg: string) => app.setPageBackground(bg)

  const onClickAddText = () => {
    const id = Utils.uniqueId()
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { size = [640, 640] } = app?.document?.pages?.[app.currentPageId]
    const canvasW = size[0]
    const canvasH = size[1]
    const offsetX = Math.random() * 10 - 10
    const offsetY = Math.random() * 10 - 10
    app.createTextShapeAtPoint([canvasW / 2 + offsetX, canvasH / 2 + offsetY], id)
  }

  const onClickAddTextPreset = (preset: TextPreset) => app.addTextPreset(preset)
  const onClickMedia = (uri, type?: TDShapeType.Sticker | TDShapeType.Image) => {
    app.addMediaFromUri(uri, app.getPagePoint(app.centerPoint, app.currentPageId), type)
  }
  const onClickAddImage = () => app.openAsset()

  const onClickItem = async (value: unknown) => {
    app.toggleEditSlot()

    switch (active) {
      case PANELS.CANVAS:
        return onClickCanvasOption(value)
      case PANELS.LAYOUT:
        return onClickGrid(value as typeof gridOptions[0])
      case PANELS.BACKGROUND:
        return onClickBackground(value as string)
      case PANELS.STICKERS:
      case PANELS.GIPHY:
        return onClickMedia(value as string)
      case PANELS.TEXT: {
        if (value) {
          return onClickAddTextPreset(value as TextPreset)
        }
        return onClickAddText()
      }
      case PANELS.IMAGE:
        return onClickAddImage()
      default:
        break
    }
  }

  useEffect(() => {
    const root = document.documentElement
    const panelW = active === PANELS.NONE ? 0 : 352
    root.style.setProperty('--panel-width', `${panelW}px`)
  }, [active])

  return (
    <>
      <Container>
        <SideItemWrapper active={active === PANELS.CANVAS} onClick={onClickPanel(PANELS.CANVAS)}>
          <SideItem>
            <CanvasIcon />
          </SideItem>
          Canvas
        </SideItemWrapper>
        <SideItemWrapper active={active === PANELS.LAYOUT} onClick={onClickPanel(PANELS.LAYOUT)}>
          <SideItem>
            <LayoutIcon />
          </SideItem>
          Layout
        </SideItemWrapper>
        <SideItemWrapper
          active={active === PANELS.BACKGROUND}
          onClick={onClickPanel(PANELS.BACKGROUND)}
        >
          <SideItem>
            <BackgroundIcon />
          </SideItem>
          Background
        </SideItemWrapper>
        <SideItemWrapper active={active === PANELS.IMAGE} onClick={onClickPanel(PANELS.IMAGE)}>
          <SideItem>
            <ImageIcon />
          </SideItem>
          Image
        </SideItemWrapper>
        {/* <SideItemWrapper active={active === PANELS.TEXT} onClick={onClickPanel(PANELS.TEXT)}>
          <SideItem>
            <TextIcon />
          </SideItem>
          Text
        </SideItemWrapper>
        <SideItemWrapper
          active={active === PANELS.STICKERS}
          onClick={onClickPanel(PANELS.STICKERS)}
        >
          <SideItem>
            <StickersIcon />
          </SideItem>
          Stickers
        </SideItemWrapper>
        <SideItemWrapper active={active === PANELS.GIPHY} onClick={onClickPanel(PANELS.GIPHY)}>
          <SideItem>
            <GiphyIcon />
          </SideItem>
          GIPHY
        </SideItemWrapper> */}
      </Container>
      <PanelWrapper>
        <Panel
          active={active}
          onClick={onClickItem}
          onAddImage={(value) => onClickMedia(value as string, TDShapeType.Image)}
          app={app}
        />
      </PanelWrapper>
    </>
  )
}

export default SideBar

const Container = styled('div', {
  minWidth: 80,
  overflow: 'overlay',
  height: '100%',
  paddingBlock: 80,
  // borderRight: '1px solid #ededed',
  background: 'var(--sidebar-bg)',
})

const SideItemWrapper = styled('button', {
  width: '100%',
  height: 80,
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  // color: '#4C4C4C',
  color: 'var(--sidebar-icon-color)',
  fontSize: 12,
  fontWeight: 400,
  lineHeight: '15px',
  border: 'none',
  background: 'none',
  padding: 0,
  cursor: 'pointer',
  userSelect: 'none',
  pointerEvents: 'all',
  [`&:hover:not(:disabled)`]: {
    backgroundColor: 'var(--sidebar-item-hover-bg)',
  },
  [`&:focus:not(:disabled)`]: {
    backgroundColor: 'var(--sidebar-item-hover-bg)',
  },
  variants: {
    active: {
      true: {
        backgroundColor: 'var(--sidebar-item-active-bg)',
      },
    },
  },
})

const SideItem = styled('div', {
  width: 40,
  // fontFamily: '"Averta PE", -apple-system, BlinkMacSystemFont',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '15px',
  textAlign: 'center',

  [`> svg`]: {
    color: 'var(--sidebar-icon-color)',
  },
})

const PanelWrapper = styled('div', {
  minWidth: 'var(--panel-width)',
  maxWidth: 'var(--panel-width)',
  height: 'calc(100vh - 80px)',
  overflowY: 'overlay',
  background: 'var(--panel-bg)',
})

const LayoutPanel = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  background: 'var(--panel-bg)',
})

const GridListWrapper = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  width: '100%',
  padding: 16,
  flex: 1,
  overflowY: 'overlay',
})

const GridWrapper = styled('div', {
  cursor: 'pointer',
  width: 160,
  height: 160,
  padding: 15,
  // backgroundColor: '#FAFAFA',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    background: 'var(--panel-item-bg-hover)',
    '& rect': {
      // stroke: '$hover',
    },
  },
})

const CustomCanvasSizeElement = styled('div', {
  border: '2px solid #707070',
  background: '#f8f8f8',
  borderRadius: 4,
  color: '#707070',
  fontSize: 14,
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const CustomGridElement = styled(GridElement, {
  boxSizing: 'border',
  position: 'relative',
  backgroundColor: 'white',
  width: 130,
  height: 130,
})

const TextPanel = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  flexFlow: 'column wrap',
  rowGap: 24,
  width: '100%',
  minHeight: '100%',
  padding: 16,
  background: 'var(--panel-bg)',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '16px',
  color: '#000000',
})

const TextButton = styled('button', {
  alignItems: 'center',
  gap: 6,
  padding: '12px 16px',
  width: 'calc(100%)',
  backgroundColor: '#4FC3C4',
  textAlign: 'center',
  border: 'none',
  borderRadius: 12,
  color: 'white',
  fontFamily: '"Montserrat", -apple-system, BlinkMacSystemFont',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 16,
  cursor: 'pointer',
  userSelect: 'none',
  pointerEvents: 'all',
  '&:disabled': {
    cursor: 'not-allowed',
    backgroundColor: '#3F9C9C',
  },
  [`&:hover:not(:disabled)`]: {
    backgroundColor: '#47AFB0',
  },
  [`&:focus:not(:disabled)`]: {
    backgroundColor: '#47AFB0',
  },
})

const TextListWrapper = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  flexWrap: 'wrap',
  width: '100%',
  flex: 1,
  overflowY: 'overlay',
})

const TextWrapper = styled('div', {
  cursor: 'pointer',
  width: 160,
  height: 160,
  padding: 16,
  // backgroundColor: '#FAFAFA',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  '&:hover': {
    '&::before': {
      content: '',
      width: 128,
      height: 128,
      position: 'absolute',
      background: 'var(--panel-item-bg-hover)',
      borderRadius: 14,
    },
  },
})
